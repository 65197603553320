import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import HeaderDecider from "../components/headerDecider"
import { connect } from "react-redux"
import White from "../images/lazy.jpg"
import json from "../test.json"
import mJson from "../mob.json"
import "../styles/journal.css"
import WhiteModal from "../components/WhiteModal"
import Mundo2 from "../components/mundo2"
import Mundo3 from "../components/mundo3"

const embed1 = `https://player.vimeo.com/external/225901207.sd.mp4?s=c1f28b7c8959a40557d7207732f6e518b540cf2c&profile_id=165`
const embed2 = `https://player.vimeo.com/external/224731021.hd.mp4?s=c40ccda6b266076283e55465d5379c5ad889f842&profile_id=175`
const embed3 = `https://player.vimeo.com/external/182132950.hd.mp4?s=1ce32ac3073b0ebe7795440be7e4f6b4774674ac&profile_id=119`

const Journal = props => {

    //note: use uselayouteffect VERY carefully; it was holding off the painting of all elements hence NO WIDTH from HeaderDecider
    //being provided

    const [modal, display] = useState(false)
    const [mundo2, displayM2] = useState(false)
    const [mundo3, displayM3] = useState(false)
    const [pic, choose] = useState({})
    const [classChosen, style] = useState()

    const modalOn = event => {

        event.preventDefault()

        const pic = event.target.dataset.src
        const embed = event.target.dataset.embed
        const picClass = event.target.getAttribute("class")

        //first if checks if modal is open or not
        if ({modal}.modal == false) {

            //this one checks if event has an embed and if so, dispatches info accordingly to white modal
            if (embed) {

                choose({type: `embed`, link: embed})
                display(true)

            } else {

                //first if is for toilets
                if(
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/16_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/17_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/18_Elefante_Bitacora.jpg" ||
                    event.target.dataset.src === "https://d975adv1u4h02.cloudfront.net/19_Elefante_Bitacora.jpg" 
                ) {
                    choose({type: `pic`, link: "https://d975adv1u4h02.cloudfront.net/test/mundo2/Elefante_Web_escusado_color.jpg"})
                    style(picClass)
                    display(true)
                } else {
                    choose({type: `pic`, link: pic})
                    style(picClass)
                    display(true)
                }

            }

        } else {

          display(false)

        }
    
    }

    const modalOff = event => {
        event.preventDefault()

        display(false)
        style()
        choose({})
    }

    const mundo2On = event => {

        event.preventDefault()

        if ({modal}.modal == false) {

            displayM2(true)

        } else {

            displayM2(false)

        }
    }

    const mundo2Off = event => {
        event.preventDefault()

        displayM2(false)
        
    }

    const mundo3On = event => {

        event.preventDefault()

        if ({modal}.modal == false) {

            displayM3(true)

        } else {

            displayM3(false)

        }
    }

    const mundo3Off = event => {
        event.preventDefault()

        displayM3(false)
        
    }

    const lazy1 = useRef("lazy1")
    const lazy2 = useRef("lazy2")
    const lazy3 = useRef("lazy3")
    const lazy4 = useRef("lazy4")
    const lazy5 = useRef("lazy5")
    const lazy6 = useRef("lazy6")
    const lazy7 = useRef("lazy7")
    const lazy8 = useRef("lazy8")
    const lazy9 = useRef("lazy9")
    const lazy10 = useRef("lazy10")
    const lazy11 = useRef("lazy11")
    const lazy12 = useRef("lazy12")
    const lazy13 = useRef("lazy13")
    const lazy14 = useRef("lazy14")
    const lazy15 = useRef("lazy15")
    const lazy16 = useRef("lazy16")
    const lazy17 = useRef("lazy17")
    const lazy18 = useRef("lazy18")
    const lazy19 = useRef("lazy19")
    const lazy20 = useRef("lazy20")
    const lazy21 = useRef("lazy21")
    const lazy22 = useRef("lazy22")
    const lazy23 = useRef("lazy23")
    const lazy24 = useRef("lazy24")
    const lazy25 = useRef("lazy25")
    const lazy26 = useRef("lazy26")
    const lazy27 = useRef("lazy27")
    const lazy28 = useRef("lazy28")
    const lazy29 = useRef("lazy29")
    const lazy30 = useRef("lazy30")
    const lazy31 = useRef("lazy31")
    const lazy32 = useRef("lazy32")
    const lazy33 = useRef("lazy33")
    const lazy34 = useRef("lazy34")
    const lazy35 = useRef("lazy35")
    const lazy36 = useRef("lazy36")
    const lazy37 = useRef("lazy37")
    const lazy38 = useRef("lazy38")
    const lazy39 = useRef("lazy39")
    const lazy40 = useRef("lazy40")
    const lazy41 = useRef("lazy41")
    const lazy42 = useRef("lazy42")
    const lazy43 = useRef("lazy43")
    const lazy44 = useRef("lazy44")
    const lazy45 = useRef("lazy45")
    const lazy46 = useRef("lazy46")
    const lazy47 = useRef("lazy47")
    const lazy48 = useRef("lazy48")
    const lazy49 = useRef("lazy49")
    const lazy50 = useRef("lazy50")
    const lazy51 = useRef("lazy51")
    const lazy52 = useRef("lazy52")
    const lazy53 = useRef("lazy53")
    const lazy54 = useRef("lazy54")
    const lazy55 = useRef("lazy55")
    const lazy56 = useRef("lazy56")
    const lazy57 = useRef("lazy57")
    const lazy58 = useRef("lazy58")
    const lazy59 = useRef("lazy59")
    const lazy60 = useRef("lazy60")
    const lazy61 = useRef("lazy61")
    const lazy62 = useRef("lazy62")
    const lazy63 = useRef("lazy63")
    const lazy64 = useRef("lazy64")
    const lazy65 = useRef("lazy65")
    const lazy66 = useRef("lazy66")
    const lazy67 = useRef("lazy67")
    const lazy68 = useRef("lazy68")
    const lazy69 = useRef("lazy69")
    const lazy70 = useRef("lazy70")
    const lazy71 = useRef("lazy71")
    const lazy72 = useRef("lazy72")
    const lazy73 = useRef("lazy73")

    let media1
    let media2
    //let media3

    let lazies = [
        lazy1,lazy2,lazy3,lazy4,lazy5,lazy6,
        lazy7,lazy8,lazy9,lazy10,lazy11,lazy12,lazy13,
        lazy14,lazy15,lazy16,lazy17,lazy18,lazy19,lazy20,
        lazy21,lazy22,lazy23,lazy24,lazy25,lazy26,lazy27,lazy28,lazy29,
        lazy30,lazy31,lazy32,lazy34,lazy35,lazy36,lazy37,lazy38,
        lazy39,lazy40,lazy41,lazy42,lazy43,lazy44,lazy45,lazy46,lazy47,
        lazy48,lazy49,lazy50,lazy51,lazy52,lazy53,lazy54,lazy55,
        lazy57,lazy58,lazy59,lazy60,lazy61,lazy62,lazy63,lazy64,lazy65,
        lazy66,lazy67,lazy68,lazy69,lazy70,lazy71,lazy73
    ]

    useEffect(()=>{

        document.body.style.background = `white`

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)
    
        })
        
    }, [props.width])

    let mother = json

    if (props.width < 768) {

        mother = mJson

        lazies = [...lazies, lazy33,lazy56]

        media1 = <img onClick={mundo3On} ref={lazy33} className="item-33 pointer" src={White} data-src={mother.pic33} data-embed={`https://player.vimeo.com/video/225901207`} alt={mother.pic33}></img>
        media2 = <img onClick={modalOn} ref={lazy56} className="item-56 pointer" src={White} data-src={mother.pic56} data-embed={`https://player.vimeo.com/video/224731021`} alt={mother.pic56}></img>
        //media3 = <img onClick={modalOn} ref={lazy72} className="item-72 pointer" src={White} data-src={mother.pic72} data-embed={`https://player.vimeo.com/video/182132950`} alt={mother.pic72}></img>

    } else if (props.width >= 768) {

        mother = json
    
        media1 = <video onClick={mundo3On} rel="preconnect" className="item-33 pointer" muted src={embed1} loop autoPlay data-embed={`https://player.vimeo.com/video/225901207`} />
        media2 = <video onClick={modalOn} rel="preconnect" className="item-56 pointer" muted src={embed2} loop autoPlay data-embed={`https://player.vimeo.com/video/224731021`} />
        //media3 = <video onClick={modalOn} rel="preconnect" className="item-72 pointer" muted src={embed3} loop autoPlay data-embed={`https://player.vimeo.com/video/182132950`} />

    }

    return [

        <HeaderDecider />,

        <div className="grid-1">
            <img onClick={modalOn} ref={lazy1} className="item-1 pointer" src={White} data-src={mother.pic1} alt={mother.pic1}></img>
            <img onClick={modalOn} ref={lazy2} className="item-2 pointer" src={White} data-src={mother.pic2} alt={mother.pic2}></img>
            <img onClick={modalOn} ref={lazy3} className="item-3 pointer" src={White} data-src={mother.pic3} alt={mother.pic3}></img>
            <img onClick={modalOn} ref={lazy4} className="item-4 pointer" src={White} data-src={mother.pic4} alt={mother.pic4}></img>
            <img onClick={modalOn} ref={lazy5} className="item-5 pointer" src={White} data-src={mother.pic5} alt={mother.pic5}></img>
            <img onClick={modalOn} ref={lazy6} className="item-6 pointer" src={White} data-src={mother.pic6} alt={mother.pic6}></img>
            <img onClick={modalOn} ref={lazy7} className="item-7 pointer" src={White} data-src={mother.pic7} alt={mother.pic7}></img>
            <img onClick={modalOn} ref={lazy8} className="item-8 pointer" src={White} data-src={mother.pic8} alt={mother.pic8}></img>
            <img onClick={modalOn} ref={lazy9} className="item-9 pointer" src={White} data-src={mother.pic9} alt={mother.pic9}></img>
            <img onClick={modalOn} ref={lazy10} className="item-10 pointer" src={White} data-src={mother.pic10} alt={mother.pic10}></img>
            <img onClick={modalOn} ref={lazy11} className="item-11 pointer" src={White} data-src={mother.pic11} alt={mother.pic11}></img>
            <img onClick={modalOn} ref={lazy12} className="item-12 pointer" src={White} data-src={mother.pic12} alt={mother.pic12}></img>
            <img onClick={modalOn} ref={lazy14} className="item-14 pointer" src={White} data-src={mother.pic14} alt={mother.pic14}></img>
            <img onClick={modalOn} ref={lazy13} className="item-13-new pointer" src={White} data-src={mother.pic13new} alt={mother.pic13new}></img>
            <img onClick={modalOn} ref={lazy15} className="item-15 pointer" src={White} data-src={mother.pic15} alt={mother.pic15}></img>
            <img onClick={modalOn} ref={lazy16} className="item-16 pointer" src={White} data-src={mother.pic16} alt={mother.pic16}></img>
            <img onClick={modalOn} ref={lazy17} className="item-17 pointer" src={White} data-src={mother.pic17} alt={mother.pic17}></img>
            <img onClick={modalOn} ref={lazy18} className="item-18 pointer" src={White} data-src={mother.pic18} alt={mother.pic18}></img>
            <img onClick={modalOn} ref={lazy19} className="item-19 pointer" src={White} data-src={mother.pic19} alt={mother.pic19}></img>
            <img onClick={modalOn} ref={lazy20} className="item-20 pointer" src={White} data-src={mother.pic20} alt={mother.pic20}></img>
            <img onClick={modalOn} ref={lazy21} className="item-21 pointer" src={White} data-src={mother.pic21} alt={mother.pic21}></img>
            <img onClick={modalOn} ref={lazy22} className="item-22 pointer" src={White} data-src={mother.pic22} alt={mother.pic22}></img>
            <img onClick={modalOn} ref={lazy23} className="item-23 pointer" src={White} data-src={mother.pic23} alt={mother.pic23}></img>
            <img onClick={modalOn} ref={lazy24} className="item-24 pointer" src={White} data-src={mother.pic24} alt={mother.pic24}></img>
            <img onClick={modalOn} ref={lazy25} className="item-25 pointer" src={White} data-src={mother.pic25} alt={mother.pic25}></img>
            <img onClick={modalOn} ref={lazy26} className="item-26 pointer" src={White} data-src={mother.pic26} alt={mother.pic26}></img>
            <img onClick={modalOn} ref={lazy27} className="item-27 pointer" src={White} data-src={mother.pic27} alt={mother.pic27}></img>
            <img onClick={modalOn} ref={lazy28} className="item-28 pointer" src={White} data-src={mother.pic28} alt={mother.pic28}></img>
            <img onClick={mundo2On} ref={lazy29} className="item-29 pointer" src={White} data-src={mother.pic29} alt={mother.pic29}></img>
            <img onClick={modalOn} ref={lazy30} className="item-30 pointer" src={White} data-src={mother.pic30} alt={mother.pic30}></img>
            <img onClick={modalOn} ref={lazy31} className="item-31 pointer" src={White} data-src={mother.pic31} alt={mother.pic31}></img>
            <img onClick={modalOn} ref={lazy32} className="item-32 pointer" src={White} data-src={mother.pic32} alt={mother.pic32}></img>
            {media1}
            <img onClick={modalOn} ref={lazy34} className="item-34 pointer" src={White} data-src={mother.pic34} alt={mother.pic34}></img>
            <img onClick={modalOn} ref={lazy35} className="item-35 pointer" src={White} data-src={mother.pic35} alt={mother.pic35}></img>
            <img onClick={modalOn} ref={lazy36} className="item-36 pointer" src={White} data-src={mother.pic36} alt={mother.pic36}></img>
            <img onClick={modalOn} ref={lazy37} className="item-37 pointer" src={White} data-src={mother.pic37} alt={mother.pic37}></img>
            <img onClick={modalOn} ref={lazy38} className="item-38 pointer" src={White} data-src={mother.pic38} alt={mother.pic38}></img>
            <img onClick={modalOn} ref={lazy39} className="item-39 pointer" src={White} data-src={mother.pic39} alt={mother.pic39}></img>
            <img onClick={modalOn} ref={lazy40} className="item-40 pointer" src={White} data-src={mother.pic40} alt={mother.pic40}></img>
            <img onClick={modalOn} ref={lazy41} className="item-41 pointer" src={White} data-src={mother.pic41} alt={mother.pic41}></img>
            <img onClick={modalOn} ref={lazy42} className="item-42 pointer" src={White} data-src={mother.pic42} alt={mother.pic42}></img>
            <img onClick={modalOn} ref={lazy43} className="item-43 pointer" src={White} data-src={mother.pic43} alt={mother.pic43}></img>
            <img onClick={modalOn} ref={lazy44} className="item-44 pointer" src={White} data-src={mother.pic44} alt={mother.pic44}></img>
            <img onClick={modalOn} ref={lazy45} className="item-45 pointer" src={White} data-src={mother.pic45} alt={mother.pic45}></img>
            <img onClick={modalOn} ref={lazy46} className="item-46 pointer" src={White} data-src={mother.pic46} alt={mother.pic46}></img>
            <img onClick={modalOn} ref={lazy47} className="item-47 pointer" src={White} data-src={mother.pic47} alt={mother.pic47}></img>
            <img onClick={modalOn} ref={lazy48} className="item-48 pointer" src={White} data-src={mother.pic48} alt={mother.pic48}></img>
            <img onClick={modalOn} ref={lazy49} className="item-49 pointer" src={White} data-src={mother.pic49} alt={mother.pic49}></img>
            <img onClick={modalOn} ref={lazy50} className="item-50 pointer" src={White} data-src={mother.pic50} alt={mother.pic50}></img>
            <img onClick={modalOn} ref={lazy51} className="item-51 pointer" src={White} data-src={mother.pic51} alt={mother.pic51}></img>
            <img onClick={modalOn} ref={lazy52} className="item-52 pointer" src={White} data-src={mother.pic52} alt={mother.pic52}></img>
            <img onClick={modalOn} ref={lazy53} className="item-53 pointer" src={White} data-src={mother.pic53} alt={mother.pic53}></img>
            <img onClick={modalOn} ref={lazy54} className="item-54 pointer" src={White} data-src={mother.pic54} alt={mother.pic54}></img>
            <img onClick={modalOn} ref={lazy55} className="item-55 pointer" src={White} data-src={mother.pic55} alt={mother.pic55}></img>
            {media2}
            <img onClick={modalOn} ref={lazy57} className="item-57 pointer" src={White} data-src={mother.pic57} alt={mother.pic57}></img>
            <img onClick={modalOn} ref={lazy58} className="item-58 pointer" src={White} data-src={mother.pic58} alt={mother.pic58}></img>
            <img onClick={modalOn} ref={lazy59} className="item-59 pointer" src={White} data-src={mother.pic59} alt={mother.pic59}></img>
            <img onClick={modalOn} ref={lazy60} className="item-60 pointer" src={White} data-src={mother.pic60} alt={mother.pic60}></img>
            <img onClick={modalOn} ref={lazy61} className="item-61 pointer" src={White} data-src={mother.pic61} alt={mother.pic61}></img>
            <img onClick={modalOn} ref={lazy62} className="item-62 pointer" src={White} data-src={mother.pic62} alt={mother.pic62}></img>
            <img onClick={modalOn} ref={lazy63} className="item-63 pointer" src={White} data-src={mother.pic63} alt={mother.pic63}></img>
            <img onClick={modalOn} ref={lazy64} className="item-64 pointer" src={White} data-src={mother.pic64} alt={mother.pic64}></img>
            <img onClick={modalOn} ref={lazy65} className="item-65 pointer" src={White} data-src={mother.pic65} alt={mother.pic65}></img>
            <img onClick={modalOn} ref={lazy66} className="item-66 pointer" src={White} data-src={mother.pic66} alt={mother.pic66}></img>
            <img onClick={modalOn} ref={lazy67} className="item-67 pointer" src={White} data-src={mother.pic67} alt={mother.pic67}></img>
            <img onClick={modalOn} ref={lazy68} className="item-68 pointer" src={White} data-src={mother.pic68} alt={mother.pic68}></img>
            <img onClick={modalOn} ref={lazy70} className="item-70 pointer" src={White} data-src={mother.pic70} alt={mother.pic70}></img>
            <img onClick={modalOn} ref={lazy69} className="item-69 pointer" src={White} data-src={mother.pic69} alt={mother.pic69}></img>
            <img onClick={modalOn} ref={lazy71} className="item-71 pointer" src={White} data-src={mother.pic71} alt={mother.pic71}></img>
            {/* {media3} */}
            <img onClick={modalOn} ref={lazy73} className="item-73 pointer" src={White} data-src={mother.pic73} alt={mother.pic73}></img>
        </div>,

        <WhiteModal off={modalOff} display={modal} pic={pic} classChosen={classChosen} />,
        <Mundo2 off={mundo2Off} display={mundo2} />,
        <Mundo3 off={mundo3Off} display={mundo3} />
    ]
        
}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        width: state.width,
        height: state.height,
    }
  }
  
  export default connect(mapStateToProps)(Journal)