import React, { useRef, useEffect, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../images/bclose.png"

const Mundo3 = props => {

    const showHide = props.display ? 'block' : 'none'

    const [embedRect, styleText] = useState({display: `none`})

    const embedRef = useRef("des1")

    const esp = 
    <div>
        <p>
            Book Trailer<br />
            Título: Oda a la soledad y a todo aquello que no pudimos ser porque así somos (2017)<br />
            Autor: Gisela Leal<br />
            Editorial: Alfaguara<br />
        </p>
    </div>

    const eng = 
    <div>
        <p>
            Book Trailer<br />
            Title: Oda a la soledad y a todo aquello que no pudimos ser porque así somos (2017)<br />
            Author: Gisela Leal<br />
            Publisher: Alfaguara<br />
        </p>
    </div>

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleText({marginLeft: embedRef.current.getBoundingClientRect().left, textAlign: `left`})

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let embedStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        embedStyle = {
            width: `300px`,
            height: `220px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        embedStyle = {
            width: `250px`,
            height: `183px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        embedStyle = {
            width: `800px`,
            height: `586px`,
            paddingBottom: `50px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }

    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <iframe ref={embedRef} style={embedStyle} src="https://player.vimeo.com/video/225901207" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <div style={grafStyle}>{props.lang === "ES" ? esp : eng}</div>
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Mundo3)